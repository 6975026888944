import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"

function SuccessCard({
  id,
  slug,
  headerInsideImg,
  headerText,
  profileIcon,
  name,
  designation,
  comment,
}: {
  id: string;
  slug: string;
  companyName: string;
  headerImg: string;
  headerInsideImg: string;
  headermobImg: string;
  headerText: string;
  profileIcon: string;
  name: string;
  designation: string;
  comment: string;
}) {
  return (
    <div className="d-flex justify-content-center mx-1 my-4 my-lg-5">
      <Container>
        <div className="successCard pb-2 pb-lg-3">
          <div className="d-flex justify-content-center">
          <div className="w-75 py-lg-5 py-3 d-flex justify-content-center text-center">
            <LazyLoadImage effect="blur" src={headerInsideImg} alt={headerText} className="w-50" width={"100%"} />
          </div>
          </div>
         
          <div className="companyHeaderText fnt-16  fw-500 fnt-sm-16 lh-26 lh-sm-20 mx-auto py-2 pb-3 ">
            {headerText}
          </div>
          <div className="px-2 px-lg-4">
            <div className="border commentSection mx-auto px-4 py-2 mb-3">
              <div className="d-flex">
                <div className="me-3 ">
                  <Link
                    to={`/success-stories/${slug}`}
                  >
                    <LazyLoadImage effect="blur" className="rounded-circle profileImageSuccessStory" src={profileIcon} alt={name} />
                  </Link>

                </div>
                <div>
                  <div className="fw-700 fnt-16 lh-26 lh-26 fnt-sm-14 lh-sm-24 ">{name}</div>
                  <div className="fw-700 fnt-16 lh-26 fnt-sm-12 lh-sm-22 color-grey">
                    {designation}
                  </div>
                </div>
              </div>
              <div className="d-flex mt-3">
                <LazyLoadImage effect="blur" src="/colon.webp" alt="SemiColon Icon" className="ms-2 me-3" />
                <div className="d-none d-lg-block">
                  <LazyLoadImage effect="blur" src="/line.webp" alt="Vertical Line" className="me-3 " />
                </div>
                <div className="d-lg-none">
                  <LazyLoadImage effect="blur" src="/lineMob.webp" alt="Vertical" className="me-3 " />
                </div>
                <i className="fw-600 fnt-16 lh-26 fnt-sm-12 lh-sm-22 color-grey-light">{comment}</i>
              </div>
            </div>
          </div>
          <div className="text-center pb-3 py-1">
            <Link
              to={`/success-stories/${slug}`}
              className="fnt-16 fw-700 lh-26 mt-2 mb-4 greenGoblin"
            >
              Read more
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SuccessCard;
