import React, { useEffect, useState } from "react";
import Acnavbar from "../components/Acnavbar";
import "../assets/scss/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../components/footer";
import Contact from "../components/Contact";
import ProductDescription from "../components/ProductDescription";
import SuccessCard from "../components/SuccessCard";
import successStoryValues from "../json/success-story.json";
import Seo from "../shared/seo";
import Wrapper from "../components/Wrapper";

function successStory() {
  const jsSucsessCardProps = JSON.parse(JSON.stringify(successStoryValues));
  const [search, setSearch] = useState("");
  const [isAuth, setIsAuth] = React.useState(false);
  const [password, setPassword] = React.useState<any>();
  const [successFilterCards, setSuccessFilterCards] =
    useState(jsSucsessCardProps);

  return (
    <Wrapper>
      <div className="common-background overflow-hidden bg-cover">
        <Seo
          title="Success Stories: Inspiring Client Achievements with Softobotics"
          description="Explore our success stories to see how Softobotics has helped businesses achieve remarkable results through our innovative IT solutions." />
        <Acnavbar home={false} pathname={'about-us'} logo={""} logoLogic={false} />
        <ProductDescription
          header="Inspiring Client Achievements with Softobotics"
          headerText="Explore our success stories to see how Softobotics has helped businesses achieve remarkable results through our innovative IT solutions."
          showButton={true}
          topheader="SUCCESS STORIES"
        />
        <div>
          {successFilterCards.map((company: any) => (
            <SuccessCard
              id={company.id}
              slug={company.slug}
              companyName={company.companyName}
              headerImg={company.headerImg}
              headerInsideImg={company.headerInsideImg}
              headermobImg={company.IntersectImageMob}
              headerText={company.headerText}
              profileIcon={company.profileIcon}
              name={company.name}
              designation={company.designation}
              comment={company.comment}
            />
          ))}
        </div>
        <div className="bg-white">
        <Contact />
        </div>
       
        <Footer />
      </div>
    </Wrapper>
  );
}

export default successStory;
